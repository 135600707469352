import React, { Component } from "react";
import Img from "gatsby-image";

export default class Banner extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="banner">
        <Img
          fluid={data.bannerImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div className="container">
          <div className="banner-details">
            <span>Hello...</span>
            <h1>{data.designation}.</h1>
            <ul className="sub-data">
              {data.bannerList.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <ul className="social">
              <li>
                <a
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <i className={`fab fa-facebook-f`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                  <a
                    href={data.mastodon}
                    target="_blank"
                    rel="me noopener noreferrer"
                    aria-label="Mastodon"
                  >
                    <i className={`fab fa-mastodon`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                  <a
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <i className={`fab fa-twitter`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                  <a
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <i className={`fab fa-instagram`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                  <a
                    href={data.linkdin}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Linkedin"
                  >
                    <i className={`fab fa-linkedin-in`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                  <a
                    href={data.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Github"
                  >
                    <i className={`fab fa-github`} aria-hidden="true"/>
                  </a>
                </li>
                <li>
                <a
                  href={data.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <i className={`fab fa-youtube`} aria-hidden="true"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
